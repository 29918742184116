<template>
  <div
    class="background"
    :style="{ backgroundImage: `url(${backgroundUrl})` }"
  ></div>
</template>

<script>
import backgroundUrl from "./assets/andy-mac.png";

export default {
  data() {
    return {
      backgroundUrl
    };
  }
};
</script>
<style>
.background {
  background-size: cover;
  min-height: 800px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
